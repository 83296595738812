import { PortableText } from "@portabletext/react";
import React from "react";

const FeaturesTab = ({ project }) => {
  const srelizerComponent = {
    marks: {
      link: ({ children, value }) => {
        const { blank, href } = value;
        return blank ? (
          <a href={href} target="_blank" rel="noopener">
            {children}
          </a>
        ) : (
          <a href={href}>{children}</a>
        );
      },
    },
  };

  return (
    <div className="tabSection" id="features-tab">
      <div className="section1">
        {project?.features?.name && (
          <ul>
            {project?.features?.name?.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        )}
        {project?.features?.links && (
          <ul>
            {project?.features?.links?.map((link, index) => (
              <li key={index}>
                <a href={link?.link}>{link?.linkName}</a>
              </li>
            ))}
          </ul>
        )}
        {project?.features?.richTextContentRaw && (
          <div className="features-rich-text-container">
            <PortableText
              value={project?.features?.richTextContentRaw}
              components={srelizerComponent}
            />
          </div>
        )}
        {!project?.features?.name &&
          !project?.features?.links &&
          "Sorry, no features to show."}
      </div>
      <div className="section2">
        <div className="img-container">
          <img src={project?.screenshot?.asset?.url}></img>
        </div>
        <p className="detailView-card-title">{project?.title}</p>
      </div>
    </div>
  );
};

export default FeaturesTab;
